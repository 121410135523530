import React from 'react'
import Index from '../../../Assets/Images/Index'
import IconBox from '../../Common/IconBox/IconBox'
import Accordion from 'react-bootstrap/Accordion';
import { signUpUrl } from '../../../Container/App';
import SellingBox from '../../Common/SellingBox/SellingBox';
import Fade from 'react-reveal/Fade';
import "./Pricing.css"
import { Helmet } from 'react-helmet';


export default function Pricing() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const onePlatform = [

    {
      img: Index.laptop,
      title: "24*5 Tech Support",
      content: "We can help you solve your queries and issues when designing your store. We are available to serve you around the clock."
    },
    {
      img: Index.card,
      title: "Multiple Payment Gateways",
      content: "Your customers have flexible payment options when buying from your stores. So, there are no payment barriers between you both."
    },
    {
      img: Index.mobilecart,
      title: "Mobile Managed Stores",
      content: "Above two plans allow you to build mobile responsive stores and you can manage your stores from your smartphones."
    },

  ]

  const PricingBoxBoxData = [
    // {
    //   title: "Tier One",
    //   content: "Start your online selling business free of cost by launching high-quality stores to build your customers.",
    //   pricing: 500,
    //   list: [
    //     "Free Custom Domain",
    //     "Mobile optimized Themes",
    //     "Easy payment Gateways",
    //     "Easy to Build Store",
    //     "Optimized Product Listings",

    //   ]
    // },
    {
      title: "Standard",
      mosttpopular: false,
      // content: "Launch a more professional and feature-packed online store with this plan and get meaningful data insights to make improvements.",
      pricing: 999,
      list: [
        "100 Products",
        "Online Store",
        "Staff Access (2)",
        "Blog Pages",
        "Discount Codes",
        "Basic Reporting",
        "1GB File Storage",
        "Manual Order Creation",
        "Free SSL Certificate",
        "Basic Customer Support",
      ]
    },
    {
      title: "Professional",
      mosttpopular: true,
      // content: "Uplift your ecommerce business with our power-packed advanced monthly plan meeting all your online business requirements.",
      pricing: 1799,
      list: [
        "Unlimited Products",
        "Online Store",
        "Staff Access (5)",
        "Blog Pages",
        "Discount Codes",
        "Advanced Reporting",
        "Unlimited File Storage",
        "Manual Order Creation",
        "Free SSL Certificate",
        "24/5 Customer Support",
      ]
    },
  ]
  const questionAnswer = [
    {
      question: "How can I get payments?",
      answer: "Your customers can choose how they want to pay you, for example, Cash on delivery, Stripe, Razorpay, etc.",
    },
    {
      question: "Do I  require a Credit card for a free trial?",
      answer: "No, you can sign up for the 15 days free trial without the need to enter the details of your credit card.",
    },
    {
      question: "Is there any setup cost?",
      answer: "No, you will not be charged any set-up cost for any of the above plans.",
    },
    {
      question: "Do I have to pay extra charges for customized Shopyxa store hosting? ",
      answer: "No, the total cost of the plans includes the domain charges. You don't have to pay any extra amount for hosting.",
    },
    {
      question: "Can I use my own domain name with Shopyxa?",
      answer: "Yes, you can use your existing domain name on Shopyxa if you want. Also, you can avail of a new one if needed.",
    },

  ]

  return (
    <main>
      <Helmet>
        <title>Best Online Store Platform for Small Business | Shopyxa</title>
        <meta name="description" content="Do You want to build a robust store to start selling online? Look no further! Shopyxa is the right partner for your business. Start Selling with us." />
        <meta name="apple-mobile-web-app-title" content="Best Online Store Platform for Small Business | Shopyxa" />
        <meta name="twitter:title" content="Best Online Store Platform for Small Business | Shopyxa" />
        <meta name="twitter:description" content="Do You want to build a robust store to start selling online? Look no further! Shopyxa is the right partner for your business. Start Selling with us." />
      </Helmet>

      <section className='fewClickSctn commonPadding greyBg'>
        <div className="container">
          <div className="commonHeading text-center">
            <Fade up>  <div className="h1">  Customized Shopyxa Plans Tailored For  <br />  Your Needs Starting From ₹17/day </div></Fade>
            <Fade up delay={200}><div className="h5">Best Online Store Platform for Small Business</div></Fade>
          </div>
          <div className="pricingGrid">
            {PricingBoxBoxData.map((data, indx) => {
              return (
                <Fade up key={indx} delay={indx * 200}>
                  <div className="pricingBox" >
                    <div className="pricingBox__header text-center">
                      {data.mosttpopular ? <span className='popular'>Most popular</span> : null}
                      <div className='offer_badge' style={{backgroundColor: !data.mosttpopular ? "#029377": "#c3451e"}}>50% Off</div>
                      <h5 className='my-2'>{data.title}</h5>
                      <div className='pricingBox__price text-center  fontRegular'>
                        <div className='h2 m-0'>
                          <del className='h4 m-0 mx-2'>₹<span>{(data.pricing).toLocaleString()}</span></del>
                          ₹<span className="fontBold">{parseInt(data.pricing / 2).toLocaleString()}</span><sup><small>+GST</small></sup></div>
                        <span className='d-block py-2 '>per month</span>
                      </div>
                    </div>
                    <div className="pricingBox__body">
                      {data.content && <p>data.content</p>}
                      <ul>
                        {data.list.map((cntnt, ind) => {
                          return (
                            <li key={ind}>{cntnt}</li>
                          )
                        })}
                      </ul>
                      <a href={signUpUrl} className="btn btn-primary w-100">Choose Plan</a>
                    </div>
                  </div>
                </Fade>
              )
            })}
          </div>
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading centerMobile">
            <Fade up >  <div className="h5 fontMedium">All Plans Include The Following:</div></Fade>
          </div>
          <div className="IconBoxRow">
            {/* {onePlatform.map((data, ind) => {
              return ( */}
            <IconBox data={onePlatform}
            // key={ind}
            // icon={data.img}
            // title={data.title}
            // content={data.content}
            />
            {/* )
            })} */}
          </div>
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">Still Have Any Questions</div>
          </div>
          <Fade up>
            <Accordion defaultActiveKey="0">
              {questionAnswer.map((data, indx) => {
                return (
                  <Accordion.Item eventKey={indx} key={indx}>
                    <Accordion.Header>{data.question}</Accordion.Header>
                    <Accordion.Body>{data.answer}</Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </Fade>
          {/* <div className="loadingText d-flex justify-content-center align-items-center">
            <span>Loading </span>  <div className="dot-pulse"></div>
          </div> */}
        </div>
      </section>
      <SellingBox
        title="Start Free Product Selling On Shopyxa"
        subtitle="Best Online Store Platform for Small Business"
        btnText="Start Free Trial"
        cntntPos="center" />
    </main>
  )
}
